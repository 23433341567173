import React from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Toast = () => {
	return (
		<React.Fragment>
			<ToastContainer />
		</React.Fragment>
	);
};
export default Toast;
